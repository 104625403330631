import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownComponent } from 'ngx-markdown';
import { NgForOf, NgIf } from '@angular/common';
import { IChatMessage, EAIChatRole } from '../../models';
import { AvatarModule } from 'primeng/avatar';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MarkdownComponent, NgForOf, NgIf, TranslateModule, AvatarModule],
  standalone: true,
})
export class ChatBoxComponent implements AfterViewInit, OnChanges {
  @Input() messages!: IChatMessage[];

  @ViewChild('chatWindow') chatWindow: ElementRef;

  protected readonly EAIChatRole = EAIChatRole;

  public ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.chatWindow) {
        this.chatWindow.nativeElement.scrollTop = this.chatWindow.nativeElement.scrollHeight;
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (
      this.chatWindow &&
      this.chatWindow.nativeElement &&
      changes['messages'].previousValue !== changes['messages'].currentValue
    ) {
      setTimeout(() => {
        if (this.chatWindow) {
          this.chatWindow.nativeElement.scrollTop = this.chatWindow.nativeElement.scrollHeight;
        }
      });
    }
  }
}
