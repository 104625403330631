import { Params } from '@angular/router';
import { ChartJsonPreviewConfig, ChartPreviewConfig } from '@selfai-platform/shell';

export function extractJsonConfigFromQueryParams(queryParams: Params): ChartJsonPreviewConfig | undefined {
  const configuration = queryParams['configuration'];
  try {
    const config = JSON.parse(configuration);
    if (config) {
      return config;
    }
  } catch {
    console.warn('Failed to parse configuration from query params');
  }

  return undefined;
}

export function extractConfigFromQueryParams(queryParams: Params): ChartPreviewConfig {
  const chartType = queryParams['chartType'];
  const dimension = queryParams['dimension'];
  const measure = queryParams['measure'];
  const measureAggregation = queryParams['measureAggregation'];
  const textWidgetContent = queryParams['textWidgetContent'];

  return {
    chartType,
    dimension,
    measure,
    measureAggregation,
    textWidgetContent,
  };
}

export function isJsonConfig(queryParams: Params): boolean {
  return queryParams['configuration'] !== undefined;
}

export function hasChartConfig(queryParams: Params): boolean {
  return queryParams['chartType'] !== undefined;
}
