import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAIModel, IApiMessage } from '../models';
import { AiChatApiService } from './ai-chat-api.service';

@Injectable({ providedIn: 'root' })
export class AiChatDomainService {
  constructor(private readonly aiChatApiService: AiChatApiService) {}

  public saveChatCompletions(message: IApiMessage, model: IAIModel): Observable<any> {
    return this.aiChatApiService.saveChatCompletions({
      model: model.model,
      messages: [message],
    });
  }

  public saveChat(messages: IApiMessage[], model: IAIModel): Observable<any> {
    return this.aiChatApiService.saveChat({
      model: model.model,
      messages,
    });
  }
}
