import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, map } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { ChatCardComponent } from '../chat-card';
import { IndexableType } from 'dexie';
import { IChat } from '../../models';

@Component({
  selector: 'app-chat-sidebar',
  templateUrl: './chat-sidebar.component.html',
  styleUrls: ['./chat-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonModule,
    InputTextModule,
    FormsModule,
    NgIf,
    DialogModule,
    ChatCardComponent,
    NgForOf,
    AsyncPipe,
    TranslateModule,
  ],
  standalone: true,
})
export class ChatSidebarComponent implements OnInit {
  public searchValue: string = '';
  public createNewChatDialogVisible = false;
  public showChats = true;
  public newChatTitle: string = this.translateService.instant('msg.ai-chat.chat-create-new-filled');

  public filteredChats$: Observable<IChat[]>;

  @Input() chat$: Observable<IChat[]>;
  @Input() selectedChatId: string | number | IndexableType;

  @Output() selectChat: EventEmitter<IChat> = new EventEmitter<IChat>();
  @Output() addNewChat: EventEmitter<string> = new EventEmitter<string>();
  @Output() removeChat: EventEmitter<IChat> = new EventEmitter<IChat>();
  @Output() renameChat: EventEmitter<Partial<IChat>> = new EventEmitter<Partial<IChat>>();

  constructor(private readonly translateService: TranslateService) {}

  public addChat() {
    if (!this.newChatTitle || !this.newChatTitle.trim().length) {
      return;
    }
    this.addNewChat.emit(this.newChatTitle);
  }

  public filter(chats$: Observable<IChat[]>): void {
    this.filteredChats$ = chats$.pipe(map((chats) => chats.map((chat) => {
      if (chat.title?.trim().toLowerCase().includes(this.searchValue.trim().toLowerCase())) {
        return chat;
      } else {
        return null;
      }
    }).filter((chat) => chat)));
  }

  public selectActiveChat(chat: IChat): void {
    this.selectChat.emit(chat);
  }

  public removeChatHandler(chat: IChat): void {
    this.removeChat.emit(chat);
  }

  public changeChatTitleHandler(event: Partial<IChat>): void {
    this.renameChat.emit(event);
  }

  public ngOnInit(): void {
    this.filteredChats$ = this.chat$;
  }
}
