import {
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  DoCheck,
  KeyValueDiffers,
} from '@angular/core';

import { Dashboard, Widget, TextWidget } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { DashboardUtil } from '../../util/dashboard.util';
import { DashboardWidgetComponent } from '../dashboard-layout/dashboard.widget.component';

@Component({
    selector: 'text-widgets-panel',
    templateUrl: './text-widget-panel.component.html',
    styleUrls: ['../../update-dashboard.component.scss'],
    standalone: false
})
export class TextWidgetPanelComponent extends AbstractComponent implements OnInit, OnDestroy, DoCheck {
  private _differ: any;

  public selectedTextWidget: TextWidget;

  public showTextEditor = false;

  public widgets: TextWidget[] = [];

  public isPreview: boolean;

  @Input()
  public dashboard: Dashboard;

  @Input()
  public widgetCompsInLayout: DashboardWidgetComponent[] = [];

  @Output()
  public invokeEvent = new EventEmitter<{ elm: ElementRef; widget: TextWidget }>();

  @Output()
  public changeEvent = new EventEmitter<{ name: string; widget: TextWidget }>();

  constructor(private differs: KeyValueDiffers, protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
    this._differ = differs.find({}).create();
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngDoCheck() {
    if (this._differ.diff(this.dashboard)) {
      this.widgets = DashboardUtil.getTextWidgets(this.dashboard);
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public isInLayout(widget: Widget): boolean {
    return -1 < this.widgetCompsInLayout.findIndex((item) => item.getWidgetId() === widget.id);
  }

  public getWidgetContents(widget: Widget): string {
    if (widget) {
      let strContents: string = widget.configuration['contents'];
      strContents = strContents.replace(/&(lt|gt);/g, function (strMatch, p1) {
        return p1 == 'lt' ? '<' : '>';
      });
      return strContents.replace(/<\/?[^>]+(>|$)/g, '');
    } else {
      return '';
    }
  }

  public setDragWidget(elm: ElementRef, item: TextWidget) {
    this.invokeEvent.emit({ elm: elm.nativeElement, widget: item });
  }

  public addWidget() {
    this.selectedTextWidget = null;
    this.showTextEditor = true;
  }

  public modifyWidget(item: Widget, isPreview?: boolean) {
    this.selectedTextWidget = <TextWidget>item;
    this.showTextEditor = true;
    this.isPreview = isPreview;
  }

  public deleteWidget(item: TextWidget) {
    this.changeEvent.emit({ name: 'DELETE', widget: item });
  }

  public setTextWidget(event: { name: string; widget: TextWidget }) {
    this.changeEvent.emit(event);
    this.showTextEditor = false;
  }
}
