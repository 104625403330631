<div *ngIf="showPipeline" class="workflow-editor">
  <div id="ppl-ng-app" class="pipelinelegacy">
    <loading-mask type="warning" string="Reconnecting"></loading-mask>
    <workflow-editor cdkDropList id="workflowEditor" (cdkDropListDropped)="onDropCube($event)"></workflow-editor>
  </div>
  <p-confirmDialog [closable]="false" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
  <selfai-platform-cube-list-sidebar *ngIf="!(chatVisible$ | async)"></selfai-platform-cube-list-sidebar>
  <div *ngIf="(cubeDialogs$ | async).length" class="collapsed-cube-dialogs">
    <p-tabView [scrollable]="true">
      <p-tabPanel *ngFor="let node of (cubeDialogs$ | async)">
        <ng-template pTemplate="header">
          <div class="mr-2">
            <div>{{ node.title }} <span *ngIf="node?.uiName">({{node.uiName}})</span></div>
          </div>
          <p-button class="mr-2" icon="pi pi-window-maximize" [text]="true" (click)="expandDialog(node.id)"></p-button>
          <p-button icon="pi pi-times" [text]="true" (click)="closeDialog(node.id)"></p-button>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
