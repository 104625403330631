import { EAIChatRole, EAIMessageType, IApiMessage, IChatMessage } from '../../models';
import { AI_BOT_NAME } from '../../constants';

export class ChatMessageFactory implements AIChatMessageFactory {
  private readonly message: string;
  private readonly role: EAIChatRole;
  private readonly username?: string;

  constructor(message: string, role: EAIChatRole, username?: string) {
    this.message = message;
    this.role = role;
    this.username = username;
  }

  public getAIMessage(): IApiMessage {
    return {
      content: this.message,
      role: this.role
    }
  }

  public getChatMessage(): IChatMessage {
    return {
      content: this.message,
      role: this.role,
      createdAt: new Date().toLocaleString(),
      username: this.username
    } as IChatMessage
  }

  static getBotTypingMessage(message?: string): IChatMessage {
    return {
      content: message,
      role: EAIChatRole.ASSISTANT,
      createdAt: new Date().toLocaleString(),
      username: AI_BOT_NAME,
      type: EAIMessageType.SYSTEM
    } as IChatMessage
  }

  static convertChatMessagesToApiMessages(messages: IChatMessage[]): IApiMessage[] {
    return messages.map((message) => ({
      content: message.content,
      role: message.role,
    }));
  }
}

export interface AIChatMessageFactory {
  getChatMessage(): IChatMessage;
  getAIMessage(): IApiMessage;
}
