import { Injectable } from '@angular/core';
import { KdConfig } from './kd-config.model';
import { ConfigService } from '../config.service';
import { KD_DEFAULT_CONFIG } from './kd-default-config.const';


@Injectable({
  providedIn: 'root',
})
export class KdConfigService {
  constructor(private readonly configService: ConfigService) {}

  public getConfig(): KdConfig {
    try {
      const kdConfig = this.configService.getConfig().knowledgeDesigner;

      return kdConfig || KD_DEFAULT_CONFIG;
    }
    catch (e: unknown) {
      console.warn(
        'Configuration for Knowledge Designer is not available. See config.json section knowledgeDesigner',
        e,
      );

      return KD_DEFAULT_CONFIG;
    }
  }
}
