export enum LocalStorageKey {
  MENU_STATE = 'menuState',
  CURRENT_USER_LANGUAGE = 'currentUserLanguage',
  FAVORITE_CUBES = 'pipelineFavoriteCubeList',
  WORKFLOW_VERSIONS = 'pipelineWorkflowVersions',
  PREFIX_SETTINGS = 'prefixSettings',
  WORKSPACE_LIST_PREFIX_SETTINGS = 'workspaceListPrefixSettings',
  FAVORITE_LIST_PREFIX_SETTINGS = 'favoriteListPrefixSettings',
  WORKBOOK_LIST_PREFIX_SETTINGS = 'dashboardListPrefixSettings',
  WORKBOOK_DASHBOARD_LIST_PREFIX_SETTINGS = 'workbookDashboardListPrefixSettings',
  AGGREGATOR_PROCESS_LIST_PREFIX_SETTINGS = 'aggregatorProcessListPrefixSettings',
  AGGREGATOR_ROUTE_LIST_PREFIX_SETTINGS = 'aggregatorRouteListPrefixSettings',
  WORKFLOW_LIST_PREFIX_SETTINGS = 'workflowListPrefixSettings',
  DATA_SOURCE_LIST_PREFIX_SETTINGS = 'dataSourceListPrefixSettings',
  TABLE_COL_SIZING = 'tableColSizing',
  AI_CHAT_HISTORY = 'aiChatHistory',
  AI_CHAT_MODEL = 'aiModel',
  SCHEDULES_LIST_PREFIX_SETTINGS = 'schedulesListPrefixSettings',
  USER_FUNCTIONS_LIST_PREFIX_SETTINGS = 'userFunctionsListPrefixSettings',
  WORKFLOW_PRESETS_LIST_PREFIX_SETTINGS = 'workflowPresetsListPrefixSettings',
}
