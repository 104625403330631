import { KdConfig } from './kd-config.model';
import { KdFeatureName } from './kd-feature-name.enum';

export const KD_DEFAULT_CONFIG: KdConfig = {
  apiUrl: 'not defined',
  enabled: false,
  features: {
    [KdFeatureName.MAIN_DASHBOARD]: {
      enabled: false,
      // main Dashboard Id
      mainDashboardId: null,
    },
    [KdFeatureName.WORKBOOKS]: {
      enabled: false,
    },
    [KdFeatureName.DASHBOARDS]: {
      enabled: false,
    },
    [KdFeatureName.CHARTS]: {
      enabled: false,
    },
    [KdFeatureName.DATAFLOWS]: {
      enabled: false,
    },
    [KdFeatureName.DATASETS]: {
      enabled: false,
    },
    [KdFeatureName.DATASOURCES]: {
      enabled: false,
    },
    [KdFeatureName.CONNECTIONS]: {
      enabled: false,
    },
    [KdFeatureName.OLLAMA]: {
      enabled: false,
    },
  },
} as const;
