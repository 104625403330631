<div class="chat-card">
  <div
    class="chat-card flex flex-nowrap justify-content-between align-items-center border-1 surface-border border-round p-2 cursor-pointer select-none hover:surface-hover transition-colors transition-duration-150"
    (click)="changeView()"
    tabindex="0"
    [ngClass]="{'surface-hover': isSelected}"
  >
    <div class="flex justify-content-between align-items-center w-full">
      <div *ngIf="!showTitleInput">
        <span class="block">{{ chat.title }}</span>
      </div>

      <div *ngIf="!showTitleInput" class="flex">
        <em class="context-menu pi pi-file-edit mr-2 ml-2" (click)="showTitleInput = !showTitleInput"></em>
        <em style="color: #d32f2f" class="context-menu pi pi-trash mr-2" (click)="confirmDelete($event)"></em>
      </div>

      <p-badge
        class="fadein animation-duration-2000 animation-iteration-infinite"
        *ngIf="chat.isLoadingMessage" severity="warning"
      />

      <input
        class="block"
        *ngIf="showTitleInput"
        pInputText
        [(ngModel)]="chatTitleInput"
        (keydown.enter)="changeTitle(); $event.stopPropagation()"
        (keydown.escape)="showTitleInput = !showTitleInput; $event.stopPropagation(); chatTitleInput = chat.title"
      />

      <div *ngIf="showTitleInput" class="flex">
        <em class="pi pi-times mr-2 ml-2" (click)="showTitleInput = !showTitleInput; $event.stopPropagation()"></em>
        <em class="pi pi-check mr-2" (click)="changeTitle(); $event.stopPropagation()"></em>
      </div>
    </div>
  </div>
</div>
<p-toast />
<p-confirmPopup />
