<div class="ddp-wrap-layout-popup ddp-wrap-edit">
  <div class="ddp-box-popup ddp-box-popup-type1" style="max-width: 1000px; width: 1000px;">
    <div class="ddp-ui-popup-title">
      <em class="ddp-icon-btntext"></em>
      {{ 'msg.board.btn.new.text' | translate }}
    </div>

    <div class="ddp-box-edit">
      <form [formGroup]="form">
        <p-editor formControlName="editor" [style]="{ height: '283px' }"></p-editor>
      </form>
    </div>

    <div class="ddp-ui-buttons">
      <a href="javascript:" (click)="isShow = false; close()" class="ddp-btn-type-popup">
        {{ 'msg.comm.btn.cancl' | translate }}
      </a>

      <a id="loc_saveTextWidget" href="javascript:" (click)="isShow = false; done()" class="ddp-btn-type-popup ddp-bg-black">
        {{ 'msg.comm.btn.done' | translate }}
      </a>
    </div>
  </div>
</div>
