<div *ngIf="showChats" class="chat-sidebar mr-2 flex flex-column h-full">
  <div class="pr-2 pt-2">
    <div class="button-container w-full mb-2 mt-2 flex">
      <p-button
        class="pr-2"
        icon="pi pi-plus-circle"
        iconPos="left"
        severity="success"
        (click)="createNewChatDialogVisible = !createNewChatDialogVisible"
      ></p-button>
      <span class="p-input-icon-left w-full">
        <i class="pi pi-search"></i>
        <input
          id="search"
          type="text"
          pInputText
          [placeholder]="'msg.ai-chat.chat-search-placeholder' | translate"
          class="w-full pr-2"
          autocomplete="off"
          [(ngModel)]="searchValue"
          (input)="filter(chat$)"
        />
      </span>
      <i
        *ngIf="searchValue.length"
        class="clear-search-input-button pi pi-times"
        (click)="searchValue = ''; filteredChats$ = chat$"
      ></i>
      <p-dialog
        [header]="'msg.ai-chat.chat-create-new' | translate"
        [modal]="true"
        [(visible)]="createNewChatDialogVisible"
        [style]="{ width: '35rem' }"
      >
        <div class="flex align-items-center gap-3 mb-3">
          <label for="chat-title" class="font-semibold w-6rem">
            {{'msg.ai-chat.chat-create-new-label' | translate}}
          </label>

          <input pInputText id="chat-title" class="flex-auto" autocomplete="off" [(ngModel)]="newChatTitle" />
        </div>

        <div class="flex justify-content-end gap-2">
          <p-button
            [label]="'msg.ai-chat.chat-create-new-cancel' | translate"
            severity="secondary"
            (click)="createNewChatDialogVisible = false"
          />

          <p-button
            [label]="'msg.ai-chat.chat-create-new-create' | translate"
            (click)="createNewChatDialogVisible = false; addChat()"
          />
        </div>
      </p-dialog>
    </div>
  </div>

  <div class="flex flex-column overflow-y-auto">
    <div class="h-full mr-2">
      <div class="flex flex-row gap-2 md:flex-column overflow-y-auto">
        <app-chat-card
          *ngFor="let chat of (filteredChats$ | async)"
          [chat]="chat"
          [isSelected]="chat.id === selectedChatId"
          (chatSelected)="selectActiveChat(chat)"
          (removeChat)="removeChatHandler($event)"
          (changeChatTitle)="changeChatTitleHandler($event)"
        ></app-chat-card>
      </div>
    </div>
  </div>
</div>
