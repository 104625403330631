import Dexie, { Table } from 'dexie';
import { IChat } from '../models';

export class ChatDB extends Dexie {
  chats!: Table<IChat, number>;

  constructor() {
    super('selfai-ai-chat');
    this.version(1).stores({
      chats: '++id, updatedAt',
    });
  }
}

export const ChatIndexDbService = new ChatDB();
