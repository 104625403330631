import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '@selfai-platform/shared';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { BadgeModule } from 'primeng/badge';
import { IChat } from '../../models';

@Component({
  selector: 'app-chat-card',
  templateUrl: './chat-card.component.html',
  styleUrls: ['./chat-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConfirmationService, AlertService],
  imports: [
    NgClass,
    NgIf,
    FormsModule,
    InputTextModule,
    ToastModule,
    ConfirmPopupModule,
    BadgeModule,
  ],
  standalone: true,
})
export class ChatCardComponent implements OnInit {
  @Input() chat!: IChat;
  @Input() isSelected!: boolean;

  @Output() changeChatTitle: EventEmitter<Partial<IChat>> = new EventEmitter<Partial<IChat>>();
  @Output() chatSelected: EventEmitter<IChat> = new EventEmitter<IChat>();
  @Output() removeChat: EventEmitter<IChat> = new EventEmitter<IChat>();

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
  ) {}

  public chatTitleInput?: string;
  public showTitleInput = false;

  public changeTitle(): void {
    if (this.chatTitleInput && this.chatTitleInput.trim().length) {
      this.changeChatTitle.emit({ id: this.chat.id, title: this.chatTitleInput });
      this.chat.title = this.chatTitleInput;
      this.showTitleInput = !this.showTitleInput;
    }
  }

  public changeView(): void {
    this.chatSelected.emit(this.chat)
  }

  public removeChatHandler(chat: IChat): void {
    this.removeChat.emit(chat);
  }

  public ngOnInit(): void {
    this.chatTitleInput = this.chat.title;
  }

  public confirmDelete(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translateService.instant('msg.ai-chat.delete-chat-confirm-message'),
      header: this.translateService.instant('msg.ai-chat.delete-chat-confirm-title'),
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'none',
      rejectIcon: 'none',

      accept: () => {
        this.alertService.success(this.translateService.instant('msg.ai-chat.delete-chat-success-message'))
        this.removeChatHandler(this.chat);
      },
    });
  }
}
