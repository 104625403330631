<div class="ddp-ui-boardside-in ddp-text-boardside">
  <div *ngIf="widgets" class="ddp-garea-boardside">
    <div class="ddp-ui-boardside-title">
      {{ 'msg.board.th.text-widget' | translate }}
      <span class="ddp-data-num">{{ widgets.length }}</span>
      <a href="javascript:" class="ddp-icon-add" (click)="addWidget()">
        <div class="ddp-ui-tooltip-info ddp-down">
          <em class="ddp-icon-view-top"></em>
          {{ 'msg.board.ui.add.text.tooltip' | translate }}
        </div>
      </a>
    </div>

    <div class="ddp-wrap-downmenu">
      <div class="ddp-box-down" [class.ddp-border-none]="0 === widgets.length">
        <ul
          class="ddp-list-text"
          cdkDropList
          [cdkDropListData]="widgets"
          [cdkDropListConnectedTo]="['grid-drop-container']"
          cdkDropListSortingDisabled
        >
          <li *ngFor="let widget of widgets" cdkDrag [cdkDragData]="widget.id" [cdkDragDisabled]="isInLayout(widget)">
            <div class="chart-drag-placeholder" *cdkDragPlaceholder></div>
            <div class="ddp-chart-images" *cdkDragPreview>
              <span class="ddp-img-txt"></span>
              <em *ngIf="isInLayout(widget)" class="ddp-icon-used"></em>
            </div>
            <a href="javascript:">
              <div class="ddp-chart-images">
                <em class="ddp-img-txt"></em>
                <em *ngIf="isInLayout(widget)" class="ddp-icon-used"></em>
              </div>
              <div class="ddp-data-info">
                <span>{{ getWidgetContents(widget) }}</span>
              </div>

              <div class="ddp-btn-control">
                <em class="ddp-icon-edit-s" (click)="modifyWidget(widget)"></em>
                <em class="ddp-icon-del-s" (click)="deleteWidget(widget)"></em>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<app-update-text
  *ngIf="showTextEditor"
  [widget]="selectedTextWidget"
  [isPreview]="isPreview"
  [dashboard]="dashboard"
  (changedWidget)="setTextWidget($event)"
  (close)="showTextEditor = false"
></app-update-text>
